var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"locale":"fr-FR","page":_vm.options.page,"pageCount":_vm.numberOfPages,"headers":_vm.headers,"items":_vm.items,"sort-by":'updated',"sort-desc":true,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      },"items-per-page":_vm.options.itemsPerPage},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"mx-3 select-project",attrs:{"items":_vm.projects,"label":"Filtrer sur un projet","item-text":"name","item-value":"id","dense":"","clearable":""},model:{value:(_vm.projectId),callback:function ($$v) {_vm.projectId=$$v},expression:"projectId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"px-3",attrs:{"append-icon":"mdi-magnify","label":"Recherche par Nom ou Label/code","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--primary"},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"target":"_blank"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"to":("/rmr-analytics/event-types-edit/" + (item.id)),"elevation":"0","fab":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","fab":"","small":""},nativeOn:{"click":function($event){_vm.dialogModel.dialog = !_vm.dialogModel.dialog;
              _vm.dialogModel.id = item.id;}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('del-confirmation',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"id":_vm.dialogModel.id},on:{"openDialog":_vm.openDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }